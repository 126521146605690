.slider {
	-webkit-appearance: none;
	width: 70%;
	height: 1px;
	background: #d3d3d3;
	outline: none;
	opacity: 0.7;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	cursor: pointer;
}

.slider:hover {
	opacity: 1;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	height: 15px;
	width: 15px;
	background-color: #fff;
	border-radius: 50%;
	cursor: pointer;
}

.slider::-moz-range-thumb {
	height: 15px;
	width: 15px;
	background-color: #fff;
	border-radius: 50%;
	cursor: pointer;
}
