* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

a {
	-webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
	width: 0px;
	height: 0px;
	background-color: rgba(255, 255, 255, 0.5);
}

* {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

img {
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

.App {
	background: rgb(58, 180, 99);
	background: linear-gradient(
		90deg,
		rgba(58, 180, 99, 1) 0%,
		rgba(48, 163, 219, 1) 24%,
		rgba(151, 53, 156, 1) 48%,
		rgba(253, 29, 29, 1) 73%,
		rgba(252, 176, 69, 1) 100%
	);
	height: 100%;
	min-height: 100%;
	width: 100%;
}

body,
html {
	touch-action: none;
}
