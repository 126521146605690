.container-drag {
	position: absolute;
	width: 820px;
	height: 400px;
	top: 0;
	left: 0;
	transform-origin: center center;
	touch-action: manipulation;
}

/*.container-drag {
	position: absolute;
	width: 480px;
	height: 270px;
	top: 0;
	left: 0;
	transform-origin: center center;
}*/

.fixShit {
	position: absolute;
	top: 0;
	left: 0;
	width: 1850px;
	height: 1450px;
}
