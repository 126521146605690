.myTheme {
	background-color: #fff !important;
	display: flex;
	justify-content: center;
	width: 1870px;
	height: 350px;
}

.hg-rows {
	font-size: 2rem;
	padding: 4rem;
	width: 100%;
}

.hide-all {
	visibility: hidden !important;
}

.kill-all {
	display: none !important;
}

.myTheme .space-invader.space-invader {
	border-radius: 50px !important;
}

.hg-button {
	height: 40px !important;
	width: 25px !important;
	border-radius: 500px !important;
	margin: 0.25rem !important;
	font-size: 14px;
}

.naming span {
	display: none;
}

.naming:after {
	content: '<';
}

.naming2 span {
	display: none;
}

.naming2:after {
	content: 'enter';
}
